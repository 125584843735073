import React from 'react'
import '../index.css';
import '../index_mobile.css';
import { Reveal } from "./utils/Reveal";

const Home = () => {

    return (
        <div className="home" id="home">
            <div>
                <Reveal>
                    <div className="my-name-is">Hello, I'm&nbsp;<span style={{ color: 'var(--primary-color)' }}> Dmitar </span>.</div>
                </Reveal>
                <Reveal>
                    <div className="my-name-is">I'm a full-stack developer.</div>
                </Reveal>
            </div>


        </div>

    )
}

export default Home;